import { useEffect, useContext } from "react"
import { Store } from "./StoreContext"
import { setContactPage } from "./actions"
import { wpURL } from "./constants"

const Contact = () => {
    const { state, dispatch } = useContext(Store);
    const {contact} = state

    useEffect(() => {
        if (!contact){
            fetch(`${wpURL}wp-json/wp/v2/pages/11`)
                .then((res) => res.json())
                .then((data) => {
                    console.log(data)
                    setContactPage(dispatch, data)
                })
                .catch((error) => console.log(error))
        }
        
    }, [])

    return (
        <>
            <h1 className="text-2xl mb-10">Contact</h1>
            
            {
                contact && 
                <div className="animate-fade-in">
                {contact.block_data.map(block => {
                    console.log(block)
                    switch (block.blockName) {
                        case "core/paragraph":
                            return <div className="mb-10 about-paragraph" dangerouslySetInnerHTML={{__html: block.rendered}}></div>
                        case "core/list":
                            return <ul className="mb-10">
                                {
                                    block.innerBlocks.map((listItem, index) => {
                                        return <li key={index} className="list-disc">{listItem.attrs.content}</li>
                                    })
                                }
                            </ul>
                        case "core/image":
                            return <img src={block.attrs.url} className="mb-10"/>
                        default:
                            console.log("Nothing to dsipaly for that block type...")
                            break;
                    }
                })}
                </div>
            }
            
            
        </>
        
    )
}

export default Contact