import { useEffect, useContext } from "react"
import { Store } from "./StoreContext"
import { setAboutPage } from "./actions"
import { wpURL } from "./constants"

const About = () => {
    const { state, dispatch } = useContext(Store);
    const {about} = state

    useEffect(() => {
        if (!about){
            fetch(`${wpURL}wp-json/wp/v2/pages/9`)
                .then((res) => res.json())
                .then((data) => {
                    console.log(data)
                    setAboutPage(dispatch, data)
                })
                .catch((error) => console.log(error))
        }
        
    }, [])

    return (
        <>
            {/* <h1 className="text-2xl mb-10">About</h1> */}
            {
                about && 
                <div className="animate-fade-in max-w-6xl">
                {about.block_data.map(block => {
                    console.log(block)
                    switch (block.blockName) {
                        case "core/paragraph":
                            return <div className="mb-6 about-paragraph" dangerouslySetInnerHTML={{__html: block.rendered}}></div>
                        case "core/list":
                            return <ul className="mb-10 about-list">
                                {
                                    block.innerBlocks.map((listItem, index) => {
                                        return <li key={index} className="list-disc ml-8" dangerouslySetInnerHTML={{__html: listItem.rendered}}></li>
                                    })
                                }
                            </ul>
                        case "core/spacer":
                            return <div style={{height: block.attrs.height}}></div>
                        case "core/image":
                            return <img src={block.attrs.url} className="mb-10 w-72"/>
                        default:
                            console.log("Nothing to dsipaly for that block type...")
                            break;
                    }
                })}
                </div>
            }
        </>
        
    )
}

export default About