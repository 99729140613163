export const setGallery = (dispatch, content) => {
    dispatch({
        type: 'SET_GALLERY',
        content: content
    })
}

export const getMoreGallery = (dispatch, gallery) => {
    dispatch({
        type: 'GET_MORE_GALLERY',
        gallery: gallery
    })
}

export const setPage = (dispatch) => {
    dispatch({
        type: "SET_PAGE"
    })
}

export const setAboutPage = (dispatch, content) => {
    dispatch({
        type: "SET_ABOUT_PAGE",
        content: content
    })
}

export const setContactPage = (dispatch, content) => {
    dispatch({
        type: "SET_CONTACT_PAGE",
        content: content
    })
}

export const endOfGallery = (dispatch) => {
    dispatch({
        type: "END_OF_GALLERY"
    })
}