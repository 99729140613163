import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Gallery from "./Gallery";
import About from "./About";
import Header from "./Header";
import Picture from "./Picture";
import Contact from "./Contact";

const App = () => {
  return (
    <Router>
      <Header/>
      <div className="mt-5 mx-5 lg:mx-20">
        <Routes>
          <Route path="/gallery" element={<Gallery/>} />
          <Route path="/" element={<About/>} />
          <Route path="/contact" element={<Contact/>} />
          <Route path="/gallery/picture/:id" element={<Picture/>} />
          <Route path="*" element={<h1>No match</h1>}/>
        </Routes>
      </div>
    </Router>
  );
}

export default App;
