import { Link, NavLink } from "react-router-dom"
import {Tumblr, Instagram, Linkedin} from "grommet-icons"

const Header = () => {
    return (
        <div className="flex items-center flex-col my-10">
            <Link to="/"><img src="/gd_logo.png" alt="GD Logo" className="mb-10"/></Link>
            
            
            <nav className="mb-2">
                <NavLink to="/" className="mr-5 font-light hover:underline hover:text-neutral-500" style={({isActive}) => isActive ? {"font-weight": "bold", "text-decoration": "underline"} : null}>About</NavLink>
                <NavLink to="/gallery" className="mr-5 font-light hover:underline hover:text-neutral-500" style={({isActive}) => isActive ? {"font-weight": "bold", "text-decoration": "underline"} : null}>Gallery</NavLink>
                <NavLink to="/contact" className="mr-5 font-light hover:underline hover:text-neutral-500" style={({isActive}) => isActive ? {"font-weight": "bold", "text-decoration": "underline"} : null}>Contact</NavLink>
                <a href="https://blog.giuliadoyle.com/" className="font-light hover:underline hover:text-neutral-500">Blog</a>
            </nav>
            <div>
                <a href="https://www.instagram.com/giulia_doyle/" target="_blank"><Instagram color="gray" className="mr-5"/></a>
                <a href="https://ca.linkedin.com/in/giulia-doyle-7753b814" target="_blank"><Linkedin color="gray"/></a>
            </div>
            
        </div>
    )
}

export default Header