import React, { createContext, useReducer } from 'react';

const initialState = {
    page: 1,
    endOfGallery: false
}

export const Store = createContext(initialState)

const setGallery = (state, content) => {
    return {...state, gallery: content}
}

const getMoreGallery = (state, gallery) => {
    return {...state, gallery: [...state.gallery, ...gallery]}
}

const setPage = (state) => {
    return {...state, page: state.page + 1}
}

const setAboutPage = (state, content) => {
    return {...state, about: content}
}

const setContactPage = (state, content) => {
    return {...state, contact: content}
}

const endOfGallery = (state) => {
    return {...state, endOfGallery: true}
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'SET_GALLERY' :
            return setGallery(state, action.content)
        case 'GET_MORE_GALLERY':
            return getMoreGallery(state, action.gallery)
        case 'SET_PAGE':
            return setPage(state)
        case 'SET_ABOUT_PAGE':
            return setAboutPage(state, action.content)
        case 'SET_CONTACT_PAGE':
            return setContactPage(state, action.content)
        case 'END_OF_GALLERY':
            return endOfGallery(state)
        default:
            console.log(`No action of type "${action.type}"`)
            return state
        }
}

export const StoreProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState)
    return <Store.Provider value={{ state, dispatch }}>{children}</Store.Provider>
}