import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { wpURL } from "./constants"
import { Link } from "react-router-dom"

const Picture = () => {
    const {id} = useParams()
    const [picture, setPicture] = useState({})
    useEffect(() => {
        
        fetch(`${wpURL}wp-json/wp/v2/media/${id}`)
                .then((res) => res.json())
                .then((data) => {
                    setPicture(data)
                    console.log(data)
                })
                .catch((err) => console.log(err))
    }, [])
    return (
        <div className="">
            {picture.source_url
            ? <div className="flex items-center mb-32 flex-col animate-fade-in">
                {/* <h1 className="uppercase font-bold text-5xl mb-10">{picture.title.rendered}</h1> */}
                <img className=" lg:w-[1000px]" src={picture.media_details.sizes.full.source_url || picture.source_url} />
                <Link to="/gallery" className="mt-8 hover:text-neutral-500 w-fit transition-all">Back to gallery</Link>
            </div>
            : null}
            
        </div>
    )
}

export default Picture