import { useContext, useEffect, useState } from "react"
import { Store } from "./StoreContext"
import { setGallery, getMoreGallery, setPage, endOfGallery } from "./actions";
import { Link } from "react-router-dom";
import { wpURL } from "./constants";

const Gallery = () => {
    const { state, dispatch } = useContext(Store);
    const {gallery} = state

    useEffect(() => {
        if (!gallery){
            fetch(`${wpURL}wp-json/wp/v2/pages/284`)
                .then((res) => res.json())
                .then((data) => {
                    console.log(data)
                    setGallery(dispatch, data)
                })
                .catch((error) => console.log(error))
        }
    }, [])

    return (
        <>
            <h1 className="text-2xl mb-10">Published by Giulia Doyle</h1>
            {
                gallery && 
                <div className="animate-fade-in max-w-screen-2xl">
                {gallery.block_data.map(block => {
                    console.log(block)
                    switch (block.blockName) {
                        case "core/paragraph":
                            return <div className="mb-10 about-paragraph" dangerouslySetInnerHTML={{__html: block.rendered}}></div>
                        case "core/list":
                            return <ul className="mb-10">
                                {
                                    block.innerBlocks.map((listItem, index) => {
                                        return <li key={index} className="list-disc">{listItem.attrs.content}</li>
                                    })
                                }
                            </ul>
                        case "core/image":
                            return <img src={block.attrs.url} className="mb-10"/>
                        case "core/gallery":
                            return <div className="mb-10 grid md:grid-cols-2 lg:grid-cols-4">
                                {
                                    block.innerBlocks.map((listItem, index) => {
                                        return <Link to={"picture/" + listItem.attrs.id} className="mr-8"><img src={listItem.attrs.url} className="mb-10"/></Link>
                                    })
                                }
                            </div>
                        default:
                            console.log("Nothing to dsipaly for that block type...")
                            break;
                    }
                })}
                </div>
            }
        </>
        
    )
}

export default Gallery